import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import car1 from '../images/car1.jpg'
import car2 from '../images/car2.jpg'
import car3 from '../images/car3.jpg'
import car4 from '../images/car4.jpg'
import car5 from '../images/car5.jpg'
import car6 from '../images/car6.jpg'
import car7 from '../images/car7.jpg'
import car8 from '../images/car8.jpg'
import car9 from '../images/car9.jpg'
import car10 from '../images/car10.jpg'
import car11 from '../images/car11.jpg'
import car12 from '../images/car12.jpg'


class Main extends React.Component {

    render() {
        function Mcarousel() {
            return (
                <Carousel showThumbs={false} style={{ maxWidth: "500px" }} showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay>
                    <div>
                        <img src={car1} />
                    </div>
                    <div>
                        <img src={car2} />
                    </div>
                    <div>
                        <img src={car3} />
                    </div>
                    <div>
                        <img src={car4} />
                    </div>
                    <div>
                        <img src={car5} />
                    </div>
                    <div>
                        <img src={car6} />
                    </div>
                    <div>
                        <img src={car7} />
                    </div>
                    <div>
                        <img src={car8} />
                    </div>
                    <div>
                        <img src={car9} />
                    </div>
                    <div>
                        <img src={car10} />
                    </div>
                    <div>
                        <img src={car11} />
                    </div>
                    <div>
                        <img src={car12} />
                    </div>
                </Carousel>
            )
        }

        let close = (
            <div
                className="close"
                onClick={() => {
                    this.props.onCloseArticle()
                }}
            ></div>
        )

        return (
            <div
                ref={this.props.setWrapperRef}
                id="main"
                style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
            >
                {  typeof window !== 'undefined' ? window.scrollTo(0, 0) : ""}

                <article
                    id="intro"
                    className={`${this.props.article === 'intro' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Influences</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>

                    {/* <h1>Riviera Agencement <br />Un état d'esprit, une démarche, un engagement</h1> */}

                    <p>Quelles que soient la nature et l’ampleur de votre projet, que vous soyez particulier ou professionnel, nous vous accompagnons dans tout ou partie de sa conception et mise en œuvre. La diversité des solutions préconisées permettra de répondre avec exactitude à vos attentes tout en tenant compte des contraintes inhérentes et spécifiques à votre projet.</p>

                    <h3>• Penser innovation</h3>
                    <p>
                        Grâce à nos outils de fabrication de pointe, nous pouvons réaliser tout type d’ouvrages. La complexité d’un projet n’est plus un frein à sa réalisation. Notre bureau d’études est à la fois un support technique capable de répondre rapidement à chaque demande et une force de proposition.                    </p>
                    <h3>• Penser polyvalence</h3>
                    <p>
                        Notre connaissance et notre maîtrise des quatre grandes familles de matériaux utilisés dans l’agencement des espaces professionnels (surfaces solides, bois et dérivés, pierres naturelles, verres) nous permettent de vous guider tout au long de votre projet dans les choix que vous aurez à effectuer. Nous maintenons une veille permanente sur l’émergence de nouveaux matériaux et leurs récentes applications.
                    </p>

                    <h3>• Penser qualitée</h3>
                    <p>
                        En améliorant en permanence nos différents procédés de fabrication, nous vous certifions une qualité de conception et de production optimale. Grâce à notre réseau de fournisseurs et partenaires pleinement identifiés et fidélisés, nous sommes en mesure de garantir les prix les plus justes.
                    </p>

                    <h3>• Penser environnement</h3>
                    <p>
                        Pour répondre à une sensibilité environnementale de plus en plus concrète, nous avons mis en place des techniques d’emballage spécifiques permettant de minimiser les volumes de déchets grâce au recyclage et à la réutilisation. Nous pouvons ainsi nous positionner comme un acteur de référence dans la protection de l’environnement.
                    </p>

                    {close}
                </article>

                <article
                    id="work1"
                    className={`${this.props.article === 'work1' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Les solides surfaces</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, trois métiers en un...</h1>
                    <p>
                        Notre entreprise s’organise autour des savoir-faire complémentaires que sont notre bureau d’études, nos ateliers de fabrication et nos équipes de pose.Pour qu’à chaque étape de votre projet, nous puissions vous apporter une solution parfaitement adaptée et maîtrisée.
                    </p>

                    <h4>Descriptif technique </h4>
                    <p>
                        Les solides surfaces sont issues d’un mélange de matières naturelles, de résines et de pigments. Leurs propriétés spécifiques combinent solidité, hygiène et esthétique. Ces surfaces haut de gamme lisses et sans pores peuvent être assemblées sans joints apparents après ponçage.
                    </p>

                    <h4>Possibilités conceptuelles </h4>
                    <p>
                        Les solides surfaces peuvent être moulées, thermoformées et taillées de même que décorées sans limites de design et de conception. Elles s’adaptent parfaitement à toute création tant dans un environnement résidentiel que professionnel.
                    </p>

                    <h4>Les finitions</h4>
                    <ul>
                        <li>Surfaces lisses, mates, satinées ou brillantes</li>
                        <li>Surfaces décoratives en relief par usinage, sablage ou gravure</li>
                        <li>Surfaces antidérapantes par sablage</li>
                        <li>Surfaces diffusantes pour ouvrage lumineux</li>
                    </ul>
                    {close}
                </article>

                <article
                    id="work2"
                    className={`${this.props.article === 'work2' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Les bois & dérivés</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, trois métiers en un...</h1>
                    <p>
                        Notre entreprise s’organise autour des savoir-faire complémentaires que sont notre bureau d’études, nos ateliers de fabrication et nos équipes de pose.Pour qu’à chaque étape de votre projet, nous puissions vous apporter une solution parfaitement adaptée et maîtrisée.
                    </p>

                    <h4>Descriptif technique </h4>
                    <p>
                        Matériau noble et naturel, le bois est robuste et respectueux de l’environnement puisque recyclable. De nombreux dérivés sont également travaillés pour l’agencement : contre-plaqués, panneaux de particules, mélaminés, panneaux plaqués revêtus de polymère, placage d’essences rares.
                    </p>

                    <h4>Possibilités conceptuelles </h4>
                    <p>
                        Présentant une palette infinie d’essences, de teintes et de finitions, le bois est choisi pour sa robustesse et sa capacité à créer un univers chaleureux et authentique. Décoratif, il se combine ou met parfaitement en valeur tout autre matériau : surfaces solides, verre, béton, acier, pierres naturelles… que ce soit en intérieur ou en extérieur.
                    </p>

                    <h4>Les finitions</h4>
                    <ul>
                        <li>Surfaces vernies, laquées avec aspect mat, satiné, brillant ou peau de pêche</li>
                        <li>Surfaces décoratives en relief par usinage, sablage ou gravure</li>
                    </ul>
                    {close}
                </article>

                <article
                    id="work3"
                    className={`${this.props.article === 'work3' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Les pierres naturelles</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, trois métiers en un...</h1>
                    <p>
                        Notre entreprise s’organise autour des savoir-faire complémentaires que sont notre bureau d’études, nos ateliers de fabrication et nos équipes de pose.Pour qu’à chaque étape de votre projet, nous puissions vous apporter une solution parfaitement adaptée et maîtrisée.
                    </p>

                    <h4>Descriptif technique </h4>
                    <p>
                        Extraites de carrières réparties à travers le monde, les pierres naturelles sont sélectionnées pour leurs spécificités géologiques ou leur couleur. Quel que soit leur type, marbre, granit, onyx, ardoise, calcaire, elles sont résistantes à l’usure, imperméables après traitement et bien entendu écologiques.
                    </p>

                    <h4>Possibilités conceptuelles </h4>
                    <p>
                        Travaillées de manière artisanale, mais aussi grâce aux nouvelles technologies liées à l’usinage numérique et aux traitements chimiques, les pierres naturelles peuvent être présentes sous forme de blocs ou en parements avec une infinie possibilité de volumes et de configurations. En intérieur ou extérieur, elles offrent noblesse, authenticité et luxe à tout projet.
                    </p>

                    <h4>Les finitions</h4>
                    <ul>
                        <li>Surfaces lisses mates, satinées ou brillantes</li>
                        <li>Surfaces décoratives en relief par usinage, sablage ou gravure</li>
                        <li>Surfaces diffusantes pour ouvrage lumineux</li>
                    </ul>
                    {close}
                </article>

                <article
                    id="work4"
                    className={`${this.props.article === 'work4' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Les verres</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, trois métiers en un...</h1>
                    <p>
                        Notre entreprise s’organise autour des savoir-faire complémentaires que sont notre bureau d’études, nos ateliers de fabrication et nos équipes de pose.Pour qu’à chaque étape de votre projet, nous puissions vous apporter une solution parfaitement adaptée et maîtrisée.
                    </p>

                    <h4>Descriptif technique </h4>
                    <p>
                        Le verre est un matériau incontournable dans la décoration d’autant que l’offre est maintenant exhaustive tant pour ses fonctionnalités que pour ses techniques de transformation et de façonnage. Il offre hygiène, isolation de très haute performance, sécurité par trempage ou feuilletage tout en conservant d’infinies possibilités de décoration en termes de couleurs et de formes.
                    </p>

                    <h4>Possibilités conceptuelles </h4>
                    <p>
                        élément de conception à part entière, le verre n’est plus limité aux ouvertures et cloisonnements. Il est dorénavant largement utilisé dans l’agencement intérieur tant pour ses qualités isolantes, protectrices, esthétiques ou de solidité, la beauté du verre résidant dans sa simplicité. Aujourd’hui, tout ou presque peut se décliner en verre.
                    </p>

                    <h4>Les finitions</h4>
                    <ul>
                        <li>Surfaces transparentes, dépolies, diffusantes, laquées</li>
                        <li>Surfaces gravées, micro billées, bouchardées</li>
                        <li>Ouvrages feuilletés avec intégration de films décoratifs ou techniques</li>
                    </ul>
                    {close}
                </article>

                <article
                    id="work5"
                    className={`${this.props.article === 'work5' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Nos unités de production</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>

                    <h1>Riviera Agencement, trois métiers en un...</h1>
                    <p>
                        Notre entreprise s’organise autour des savoir-faire complémentaires que sont notre bureau d’études, nos ateliers de fabrication et nos équipes de pose.Pour qu’à chaque étape de votre projet, nous puissions vous apporter une solution parfaitement adaptée et maîtrisée.
                    </p>

                    <p>
                        Devant la variété des chantiers et leur complexité, nous nous sommes entourés de multiples savoir-faire puisés tant dans la tradition que dans les techniques les plus modernes pour vous garantir qualité et compétitivité : notre bureau d’études, nos ateliers de production dotés d’équipements de pointe, mais aussi de véritables professionnels des matériaux (surfaces solides, bois, verres et pierres naturelles). Cette offre globale et pluridisciplinaire nous permet de vous guider et de vous accompagner tout au long de votre projet dans un souci de transparence et de confiance.
                    </p>

                    {close}
                </article>

                <article
                    id="work6"
                    className={`${this.props.article === 'work6' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Notre logistique</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>

                    <h1>Riviera Agencement, trois métiers en un...</h1>
                    <p>
                        Notre entreprise s’organise autour des savoir-faire complémentaires que sont notre bureau d’études, nos ateliers de fabrication et nos équipes de pose.Pour qu’à chaque étape de votre projet, nous puissions vous apporter une solution parfaitement adaptée et maîtrisée.
                    </p>

                    <p>
                        En développant notre propre réseau de logistique, nous contrôlons l’ensemble de votre projet, de la conception à la pose finale. Nous assurons la planification, l’organisation et la coordination de tous les savoir-faire afin de respecter les délais de livraison prévus. En suivant un cahier des charges rigoureux, nous conservons néanmoins réactivité et souplesse de travail. Le professionnalisme de nos propres équipes de pose garantit un ouvrage soigné, garant de la qualité perçue de votre projet et une fin de chantier conforme à vos exigences.
                    </p>

                    {close}
                </article>

                <article
                    id="domaines1"
                    className={`${this.props.article === 'domaines1' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">CHR</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, Metteur en scène de vos espaces</h1>
                    <p>
                        Parce que la réussite commerciale d’un espace CHR tient autant à la qualité des produits et du service qu’à l’ambiance qu’il dégage, Riviera Agencement met à votre service son expertise concrète du secteur pour éviter pièges et erreurs d’aménagement. La diversité de nos solutions en termes de connaissance des produits, d’optimisation des espaces et des normes d’hygiène et de sécurité permet que l’expérience client soit unique (décor, ambiance, temps de service, confidentialité, intimité).
                    </p>
                    {close}
                </article>

                <article
                    id="domaines2"
                    className={`${this.props.article === 'domaines2' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Tertiaire</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, Metteur en scène de vos espaces</h1>
                    <p>
                        De simples espaces de travail, les bureaux sont devenus des lieux de vie qui doivent se conformer aux contraintes liées à leur utilisation. Riviera Agencement vous aidera à cerner vos besoins, à combler vos attentes tout en s’adaptant aux exigences techniques particulières (luminosité, acoustique, cloisonnement, optimisation des espaces et normes de sécurité).
                    </p>
                    {close}
                </article>

                <article
                    id="domaines3"
                    className={`${this.props.article === 'domaines3' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Domaine médical</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, Metteur en scène de vos espaces</h1>
                    <p>
                        Les exigences des patients comme des personnels hospitaliers ont largement évolué. On veut aujourd’hui du fonctionnel, mais aussi de l’esthétique, de la lumière, du confort. Notre expertise dans le domaine médical vous accompagnera tout au long de votre projet : respect des dernières réglementations en termes d’hygiène, choix des matériaux pour leur pérennité et leur entretien, mais aussi mise en valeur des volumes pour améliorer accueil et confort.
                    </p>
                    {close}
                </article>

                <article
                    id="domaines4"
                    className={`${this.props.article === 'domaines4' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Boutiques & magasins</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, Metteur en scène de vos espaces</h1>
                    <p>
                        L’un des piliers de la réussite d’une entreprise commerciale est son aménagement. Riviera Agencement vous guidera dans sa création ou sa rénovation afin de séduire au mieux votre clientèle. Grâce à notre bureau d’études et notre expertise des matériaux, nous serons à même de vous accompagner dans cette recherche d’attractivité : habillages muraux, vitrines et présentoirs, ouvrages lumineux, circulation, mobiliers…
                    </p>
                    {close}
                </article>

                <article
                    id="domaines5"
                    className={`${this.props.article === 'domaines5' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Résidentiel</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, Metteur en scène de vos espaces</h1>
                    <p>
                        Par sa maîtrise des matériaux et des techniques de mise en œuvre, Riviera Agencement vous permet de laisser libre cours à votre imagination. Notre bureau d’études vous accompagnera  pas à pas pour faire de vos envies une réalité. La maîtrise des savoir-faire de nos équipes garantit des finitions haut de gamme.
                    </p>
                    {close}
                </article>

                <article
                    id="domaines6"
                    className={`${this.props.article === 'domaines6' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Résidentiel</h2>
                    <span className="image main">
                        {Mcarousel()}
                    </span>
                    <h1>Riviera Agencement, Metteur en scène de vos espaces</h1>
                    <p>
                        Par sa maîtrise des matériaux et des techniques de mise en œuvre, Riviera Agencement vous permet de laisser libre cours à votre imagination. Notre bureau d’études vous accompagnera  pas à pas pour faire de vos envies une réalité. La maîtrise des savoir-faire de nos équipes garantit des finitions haut de gamme.
                    </p>
                    {close}
                </article>

                <article
                    id="contact"
                    className={`${this.props.article === 'contact' ? 'active' : ''} ${
                        this.props.articleTimeout ? 'timeout' : ''
                        }`}
                    style={{ display: 'none' }}
                >
                    <h2 className="major">Contact</h2>
                    <form method="post" action="#">
                        <div className="field half first">
                            <label htmlFor="name">Name</label>
                            <input type="text" name="name" id="name" />
                        </div>
                        <div className="field half">
                            <label htmlFor="email">Email</label>
                            <input type="text" name="email" id="email" />
                        </div>
                        <div className="field">
                            <label htmlFor="message">Message</label>
                            <textarea name="message" id="message" rows="4"></textarea>
                        </div>
                        <ul className="actions">
                            <li>
                                <input type="submit" value="Send Message" className="special" />
                            </li>
                            <li>
                                <input type="reset" value="Reset" />
                            </li>
                        </ul>
                    </form>
                    <ul className="icons">
                        <li>
                            <a
                                href="https://twitter.com/HuntaroSan"
                                className="icon fa-twitter"
                            >
                                <span className="label">Twitter</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://codebushi.com" className="icon fa-facebook">
                                <span className="label">Facebook</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://codebushi.com" className="icon fa-instagram">
                                <span className="label">Instagram</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://github.com/codebushi/gatsby-starter-dimension"
                                className="icon fa-github"
                            >
                                <span className="label">GitHub</span>
                            </a>
                        </li>
                    </ul>
                    {close}
                </article>
            </div>
        )
    }
}

Main.propTypes = {
    route: PropTypes.object,
    article: PropTypes.string,
    articleTimeout: PropTypes.bool,
    onCloseArticle: PropTypes.func,
    timeout: PropTypes.bool,
    setWrapperRef: PropTypes.func.isRequired,
}

export default Main
